<template>
  <div class="mt-4">
    <div>
      <div class="">
        <form>
          <span class="md:text-xs mr-2">Show</span>
          <select
            class="md:text-xs h-11 md:h-8 border-white rounded-lg"
            v-model="searchDataLimit"
            name="limit"
            id=""
          >
            <option selected value="10">10</option>
            <option value="20">20</option>
            <option value="40">40</option>
            <option value="60">60</option>
            <option value="80">80</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
          <span class="md:text-xs ml-2">entries</span>
        </form>
      </div>
      <div class="overflow-auto ">
        <t-table
          id="my-table"
          :classes="table_classes"
          :headers="table_header"
          :data="table_data"
        >
          <template slot="thead" slot-scope="props">
            <thead :class="props.theadClass">
              <tr :class="props.trClass">
                <th
                  v-for="(item, index) in props.data"
                  :class="props.thClass"
                  :key="index"
                >
                  {{ item.text }}
                </th>
              </tr>
            </thead>
          </template>

          <template slot="column" slot-scope="props">
            <td v-if="props.text === 'btn'" :class="props.tdClass">
              <t-dropdown text=". . ." :classes="table_dropdown_class">
                <div class="py-1 rounded-md shadow-xs">
                  <a
                    href="#"
                    class="block px-4 py-2 md:text-xs  leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                    role="menuitem"
                  >
                    Edit {{ mode }}
                  </a>
                </div>
              </t-dropdown>
            </td>
            <td @click="ClickRow(props)" v-else :class="props.tdClass">
              {{ props.text }}
            </td>
          </template>
        </t-table>
      </div>

      <div v-if="loading.search" class="flex justify-center flex-col">
        <div>
          <p class="text-red-700 text-center md:text-xs font-bold  ">
            Loading,please wait...
          </p>
        </div>
        <div class="mt-2">
          <svg
            class="ml-auto mr-auto animate-spin"
            width="25"
            height="25"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48 25C48 12.2975 37.7025 2 25 2"
              stroke="black"
              stroke-width="4"
            />
            <path
              d="M48 25C48 37.7025 37.7025 48 25 48"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M2 25C2 12.2975 12.2975 2 25 2"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M25 48C12.2975 48 2 37.7025 2 25"
              stroke="#C9000C"
              stroke-width="4"
            />
          </svg>
        </div>
      </div>

      <div
        v-if="search.result.length < 1 && loading.search != true"
        class="mb-6 mt-2"
      >
        <p class="text-center md:text-xs">No results found</p>
      </div>
      <div class=" mt-2 text-center">
        <button
          :class="
            search.hasPrevious != true ? 'cursor-not-allowed' : 'cursor-pointer'
          "
          :disabled="search.hasPrevious != true || loading.search != false"
          @click="Navigate('prev')"
          class=" text-darkblue md:text-xs  hover:text-blue-600 font-bold"
        >
          Prev
        </button>

        <span class="ml-2 mr-2 md:text-xs"
          >{{ current_page }}/{{ number_of_pages }}</span
        >
        <button
          :class="
            search.hasMore != true ? 'cursor-not-allowed' : 'cursor-pointer'
          "
          :disabled="search.hasMore != true || loading.search != false"
          @click="Navigate('next')"
          class=" text-darkblue md:text-xs hover:text-blue-600 font-bold"
        >
          Next
        </button>
      </div>
      <div class="mb-6  md:text-xs mt-2 text-center">
        <p>
          <span class="text-darkblue"> Total Count </span>:{{
            search.totalCount
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import gql from "graphql-tag";
  import { permissionMixin } from "@/assets/helpers/permissionMixin";

  export default {
    name: "Table",
    mixins: [permissionMixin],

    props: {
      table_data: Array,
      hide_add_new_button: Boolean,
      mode: String,
      search_data: Object,
      table_header: Array,
      TableButton: Function,
      FormatTableData: Function,
    },
    apollo: {
      search: {
        query: gql`
          query search(
            $cursor: Float!
            $searchQuery: String
            $filter: String
            $dateFilter: DateFilterInput
            $model: String!
            $type: SearchType!
            $limit: Float!
          ) {
            search(
              query: {
                cursor: $cursor
                searchQuery: $searchQuery
                filter: $filter
                model: $model
                type: $type
                limit: $limit
                dateFilter: $dateFilter
              }
            ) {
              result {
                ... on User {
                  _id
                  name
                  roleId {
                    name
                  }
                  phoneNumber
                  serialId
                  createdAt
                  isDeleted
                }
                ... on Invoice {
                  _id
                  customerId {
                    customerName
                  }
                  authorId {
                    _id
                    name
                  }
                  serialId
                  isDeleted

                  payments {
                    amount
                  }
                  completedPayment
                  invoiceTotal
                  createdAt
                  serialId
                  isDeleted
                }
                ... on Statement {
                  _id
                  partnerId {
                    _id
                    name
                  }
                  authorId {
                    _id
                    name
                  }
                  serialId
                  isDeleted

                  type
                  statementBalance
                  statementTotal
                  createdAt
                  serialId
                  isDeleted
                }
                ... on Stock {
                  _id
                  itemName
                  unit
                  serialId
                  unitSellingPrice
                  authorId {
                    _id
                    name
                  }
                  quantityRemaining
                  createdAt
                }
                ... on Partner {
                  _id
                  name
                  email
                  partnerPhone: phoneNumber

                  serialId
                  createdAt
                }
                ... on Purchase {
                  _id
                  sellerId {
                    _id
                    name
                  }
                  authorId {
                    _id
                    name
                  }
                  purchaseTotal
                  createdAt
                  serialId
                  isDeleted
                }
                ... on User {
                  _id
                  name
                  position
                  phoneNumber
                  roleId {
                    _id
                    name
                  }
                  createdAt
                  serialId
                  isDeleted
                }
                ... on Role {
                  _id
                  name
                  createdAt
                  serialId
                }
                ... on ExpenseItem {
                  _id
                  expenseItemName
                  serialId
                  createdAt
                }
                ... on Expense {
                  _id
                  expenseItemId {
                    _id
                    expenseItemName
                  }
                  authorId {
                    _id
                    name
                  }
                  staffId {
                    _id
                    name
                  }
                  amount
                  serialId
                  createdAt
                }
                ... on Quote {
                  _id
                  clientName
                  serialId
                  isDeleted

                  clientPhone
                  quoteTotal
                  authorId {
                    _id
                    name
                  }
                  createdAt
                }
                ... on Customer {
                  _id
                  customerName
                  email
                  customerPhone: phoneNumber
                  createdAt
                  serialId
                  isDeleted
                }
                ... on Seller {
                  _id
                  sellerName: name
                  email
                  sellerPhone: phoneNumber
                  createdAt

                  serialId
                  isDeleted
                }

                ... on Vehicle {
                  _id
                  plateNumber
                  manufacturer
                  model
                  authorId {
                    _id
                    name
                  }
                  serialId
                  isDeleted
                  createdAt
                  updatedAt
                }
                ... on VehicleTrip {
                  _id
                  location
                  income
                  expense
                  balance
                  authorId {
                    _id
                    name
                  }
                  vehicleId {
                    _id
                    manufacturer
                    plateNumber
                  }
                  serialId
                  isDeleted
                  createdAt
                  updatedAt
                }
              }
              totalCount
              hasMore
              hasPrevious
            }
          }
        `,
        variables() {
          return {
            cursor: Number(this.searchCursor),
            searchQuery: this.search_data.searchQuery,
            filter: this.search_data.filter,
            model: this.search_data.model,
            limit: Number(this.searchDataLimit),
            type: this.searchType,
            dateFilter: this.search_data.dateFilter,
          };
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        skip() {
          if (this.appPermissions[`${this.mode.toLowerCase()}`]) {
            return (
              this.appPermissions[`${this.mode.toLowerCase()}`]["read"] == false
            );
          }

          return true;
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.search = true;
            return;
          }

          this.loading.search = false;
        },
      },
    },
    data() {
      return {
        search: {
          result: [],
          hasMore: false,
          hasPrev: false,
        },

        skipQuery: true,
        searchCursor: 0,
        searchDataLimit: 10,
        loading: {
          search: false,
        },
        searchType: "GT",
        icons: {
          pen: require("../assets/imgs/icons/white-pen.svg"),
          blue_card: require("../assets/imgs/icons/blue-cardbg.svg"),
          red_card: require("../assets/imgs/icons/red-cardbg.svg"),

          receipt: require("../assets/imgs/icons/receipt.svg"),
        },
        pagination_classes: {
          wrapper: "table border-collapse text-center  mx-auto shadow-sm",
          element:
            "w-8 h-8 border border-gray-200 table-cell hover:border-blue-100",
          activeElement:
            "w-8 h-8 rounded-full  table-cell hover:border-blue-600",
          disabledElement: "w-8 h-8 border border-gray-200 table-cell",
          ellipsisElement:
            "w-8 h-8 border border-gray-200 hidden md:table-cell",
          activeButton:
            "bg-darkblue rounded-full w-full h-full text-white hover:bg-blue-600 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
          disabledButton:
            "opacity-25 w-full pl-3 pr-3 h-full cursor-not-allowed transition duration-100 ease-in-out",
          button:
            "hover:bg-darkblue pl-3 pr-3 hover:text-white text-darkblue rounded-full w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
          ellipsis: "",
        },

        table_classes: {
          table:
            "min-w-full  text-darkblue divide-y md:text-center divide-gray-100 shadow-sm border-gray-200 border",
          thead: "border-b-2 text-xs border-darkblue ",
          theadTr: "",
          theadTh:
            "px-3 py-2 font-bold md:text-center text-left bg-transparent ",
          tbody: "bg-white divide-y divide-gray-100",
          tr: "cursor-pointer hover:bg-dashboardbody  rounded-lg text-xs",
          td: "px-3 py-2  whitespace-no-wrap",
          tfoot: "",
          tfootTr: "",
          tfootTd: "",
        },
        table_dropdown_class: {
          button:
            "block font-black break-normal px-4 py-2 text-black transition duration-100 ease-in-out bg-transparent border border-transparent rounded  hover:text-green-400 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
          wrapper: "inline-flex flex-col",
          dropdownWrapper: "relative z-10",
          dropdown:
            "origin-top-left absolute left-0 w-32 rounded shadow bg-white mt-1",
          enterClass: "",
          enterActiveClass:
            "transition ease-out duration-100 transform opacity-0 scale-95",
          enterToClass: "transform opacity-100 scale-100",
          leaveClass: "transition ease-in transform opacity-100 scale-100",
          leaveActiveClass: "",
          leaveToClass: "transform opacity-0 scale-95 duration-75",
        },
        current_page: 1,
        per_page: 5,
      };
    },
    computed: {
      watchedSearchQuery: function() {
        return this.search_data.searchQuery;
      },
      total_rows: function() {
        return this.table_data.length;
      },
      paginated_table_data: function() {
        let offset = (this.current_page - 1) * this.per_page;
        let splice_stop = offset + this.per_page;

        return this.table_data.slice(offset, splice_stop);
      },
      number_of_pages: function() {
        return Math.ceil(
          Number(this.search.totalCount) / Number(this.searchDataLimit)
        );
      },
    },
    watch: {
      search(newValue) {
        this.FormatTableData(newValue.result);
      },
      searchDataLimit() {
        this.searchCursor = 0;
        this.searchType = "GT";
        this.current_page = 1;
      },
      watchedSearchQuery() {
        this.searchCursor = 0;
        this.searchType = "GT";
        this.current_page = 1;
      },

      mode() {
        this.searchType = "GT";
        this.current_page = 1;

        this.searchCursor = 0;
      },
    },
    methods: {
      ClickRow(row) {
        this.$emit("clicked-table", this.search.result[row.rowIndex]._id);
      },

      Navigate(type) {
        switch (type) {
          case "prev":
            this.searchType = "LT";
            this.current_page--;

            this.searchCursor = this.search.result[0].serialId;
            break;

          case "next":
            this.searchType = "GT";
            this.current_page++;
            this.searchCursor = this.search.result[
              this.search.result.length - 1
            ].serialId;
            break;

          default:
            break;
        }
        // this.$router.push({
        //   path: this.$route.path,
        //   query: { cursor: this.searchCursor },
        // });
      },
    },

    mounted() {
      this.$apollo.queries.search.setOptions({
        fetchPolicy: "network-only",
      });
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }
</style>
